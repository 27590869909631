<template>
  <ThemeProvider :color="{ middle: { 'bg-base': '#fff' }, primary: { DEFAULT: '#1896ff' } }">
    <div class="login-box rounded-sm">
      <a-form :model="form" :wrapperCol="{ span: 24 }" @finish="saveLogin"
        class="login-form w-[400px] p-lg xl:w-[440px] xl:p-xl h-fit text-text" :rules="rules">
        <!-- <div class="third-platform">
          <div class="third-title mb-md text-lg">第三方登录：</div>
          <div class="third-list flex text-[28px]">
            <WechatOutlined class="icon wechat flex-1 cursor-pointer text-gray-400 hover:text-green-600" />
            <TwitterOutlined class="icon twitter flex-1 cursor-pointer text-gray-400 hover:text-blue-400" />
            <QqOutlined class="icon qq flex-1 cursor-pointer text-gray-400 hover:text-red-600" />
          </div>
        </div> -->
        <a-divider>登录</a-divider>
        <a-form-item :required="true" name="username">
          <a-input v-model:value="form.username" autocomplete="请输入用户名" placeholder="请输入用户名"
            class="login-input h-[40px]" />
        </a-form-item>
        <a-form-item :required="true" name="password">
          <a-input-password v-model:value="form.password" autocomplete="请输入用户名" placeholder="请输入登录密码"
            class="login-input h-[40px]" />
        </a-form-item>
        <a-button htmlType="submit" class="h-[40px] w-full" type="primary" :loading="loading"> 登录 </a-button>
        <!-- <a-divider></a-divider> -->
      </a-form>
    </div>
  </ThemeProvider>
</template>
<script lang="ts" setup>
import { reactive, ref, inject } from 'vue';
import { useAccountStore } from '@/store';
import { ThemeProvider } from 'stepin';
import { messageError } from '@/utils/antdMess';
import type { Rule } from 'ant-design-vue/es/form';

const debounce: Function = inject("debounce")
const rules: Record<string, Rule[]> = {
  username: [{ required: true, message: "请输入用户名" }],
  password: [{ required: true, message: "请输入密码" }],
}
export interface LoginFormProps {
  username: string;
  password: string;
}
const loading = ref(false);

const form = reactive({
  username: undefined,
  password: undefined,
});

const emit = defineEmits<{
  (e: 'success', fields: LoginFormProps): void;
  (e: 'failure', reason: string, fields: LoginFormProps): void;
}>();

const accountStore = useAccountStore();
const saveLogin = debounce(login, 1000)

function login(params: LoginFormProps) {
  loading.value = true;
  accountStore
    .login(params.username, params.password)
    .then((res) => {
      emit('success', params);
      loading.value = false;
    })
    .catch((e) => {
      console.log(e);
      // 登录错误时修改状态码404
      messageError(e.message)
      emit('failure', e.message, params);
      loading.value = false;
    })
    .finally(() => (loading.value = false));
}
</script>
