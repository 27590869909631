import { NavigationGuard, NavigationHookAfter } from 'vue-router';
import http from '@/store/http';
import { useAccountStore, useMenuStore } from '@/store';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import Cookie from 'js-cookie';
import { messageError } from '@/utils/antdMess'

NProgress.configure({ showSpinner: false });

interface NaviGuard {
  before?: NavigationGuard;
  after?: NavigationHookAfter;
}

// 路由守卫
const loginGuard: NavigationGuard = function (to, from) {
  if (to.meta.title) {
    document.title = 'OSCE管理系统-' + to.meta.title
  }
  let token = Cookie.get("token")
  if (!token && to.path != '/login') {
    messageError("登录失效，请重新登录")
    localStorage.clear();
    return '/login';

  }
  const account = useAccountStore();
  if (http.checkAuthorization() && !/^\/(login|home)?$/.test(to.fullPath)) {
    return '/login';
    account.setLogged(false);
  }
};
// 进度条
const ProgressGuard: NaviGuard = {
  before(to, from) {
    NProgress.start();
  },
  after(to, from) {
    NProgress.done();
  },
};

// 404 not found
const NotFoundGuard: NaviGuard = {
  before(to, from) {
    const { loading } = useMenuStore();
    if (to.meta._is404Page && loading) {
      to.params.loading = true as any;
    }
  },
};

export default {
  before: [ProgressGuard.before, loginGuard, NotFoundGuard.before],
  after: [ProgressGuard.after],
};
