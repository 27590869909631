import { defineStore, storeToRefs } from 'pinia';
import http from './http';
import { ref, watch } from 'vue';
import { Response } from '@/types';
import { RouteOption } from '@/router/interface';
import { addRoutes, menuRoutes } from '@/router/dynamicRoutes';
import { useSettingStore } from './setting';
import { RouteRecordRaw, RouteMeta } from 'vue-router';
import { useAuthStore } from '@/plugins';
import router from '@/router';
export interface MenuProps {
  show?: number;
  id?: number;
  name: string;
  path: string;
  title?: string;
  icon?: string;
  badge?: number | string;
  target?: '_self' | '_blank';
  link?: string;
  component: string;
  renderMenu?: boolean;
  permission?: string;
  parent?: string;
  children?: MenuProps[];
  cacheable?: boolean;
  view?: string;
}

/**
 * 过滤菜单
 * @param routes
 * @param parentPermission
 */
function doMenuFilter(routes: Readonly<RouteRecordRaw[]>, parentPermission?: string) {
  const { hasAuthority } = useAuthStore();

  const setCache = (meta: RouteMeta) => {
    meta._cache = {
      renderMenu: meta.renderMenu,
    };
  };

  routes.forEach((route) => {
    const required = route.meta?.permission ?? parentPermission;
    if (route.meta?.renderMenu === undefined && required) {
      route.meta = route.meta ?? {};
      setCache(route.meta);
      route.meta.renderMenu = hasAuthority(route.meta.permission);
    }
    if (route.children) {
      doMenuFilter(route.children, required);
    }
  });
}

/**
 * 重置过滤
 * @param routes
 */
function resetMenuFilter(routes: Readonly<RouteRecordRaw[]>) {
  const resetCache = (meta: RouteMeta) => {
    if (meta._cache) {
      meta.renderMenu = meta._cache?.renderMenu;
    }
    delete meta._cache;
  };
  routes.forEach((route) => {
    if (route.meta) {
      resetCache(route.meta);
    }
    if (route.children) {
      resetMenuFilter(route.children);
    }
  });
}

// 菜单数据转为路由数据
const toRoutes = (list: MenuProps[]): RouteOption[] => {
  return list.map((item) => ({
    name: item.name,
    path: item.path,
    component: '@/pages' + item.path,
    children: item.children && toRoutes(item.children),
    meta: {
      // title: item.title,
      // permission: item.permission,
      icon: item.icon,
      // view: item.path === "/needChecking" ? "blank" : undefined
      // renderMenu: item.renderMenu,
      // cacheable: item.cacheable,
      // href: item.link,
      // badge: /^(false|true)$/i.test(item.badge + '') ? JSON.parse(item.badge + '') : item.badge,
      // target: item.target,
      // view: item.view,
    },
  }));
};

export const useMenuStore = defineStore('menu', () => {
  const menuList = ref<MenuProps[]>([]);

  const loading = ref(false);

  const { filterMenu } = storeToRefs(useSettingStore());

  const checkMenuPermission = () => {
    if (filterMenu.value) {
      doMenuFilter(router.options.routes);
    } else {
      resetMenuFilter(router.options.routes);
    }
  };

  checkMenuPermission();

  watch(filterMenu, checkMenuPermission);
  function getMenuListss(data) {
    let menuList = [];
    menuList = data;
    localStorage.setItem('stepin-menu', JSON.stringify(menuList));
    return menuList;
  }
  function getMenu(data) {
    let menuList = getMenuListss(data);
    return menuList
  }
  async function getMenuList() {
    menuRoutes()
    loading.value = true;
    return http
      .request<MenuProps[], Response<MenuProps[]>>('/menu/mymenu', 'GET')
      .then((res) => {
        if (res.code == 200) {
          let showData = res.data.filter(item => {
            return item.show == 1
          })
          showData.forEach(item => {
            if (item.children && item.children.length > 0) {
              item.children = item.children.filter(items => {
                return items.show == 1
              })
            }
          })
          let data = getMenu(showData) || []
          addRoutes(toRoutes(data));
          checkMenuPermission();
          return data;
        }

      })
      .finally(() => (loading.value = false));
  }

  return {
    loading,
    menuList,
    getMenuList,
  };
});
