import { defineStore } from 'pinia';
import http from './http';
import { Response } from '@/types';
import { useMenuStore } from './menu';
import { useAuthStore } from '@/plugins';
import { messageError } from '@/utils/antdMess'
export interface Profile {
  account: Account;
  permissions: string[];
  role: string;
}
export interface Account {
  username: string;
  avatar: string;
  gender: number;
}

export type TokenResult = {
  access_token: string;
  expires_in: number;
};
export const useAccountStore = defineStore('account', {
  state() {
    return {
      account: {} as Account,
      permissions: [] as string[],
      role: '',
      logged: true
    };
  },
  actions: {
    async login(username: string, password: string) {
      return http
        .request<TokenResult, Response<TokenResult>>('/authorizations', 'post_json', { username, password })
        .then(async (response) => {
          console.log(response);
          if (response.code == 200) {
            http.setAuthorization(`Bearer ${response.data.access_token}`, response.data.expires_in);
            this.logged = true;

            await this.profile()
            await useMenuStore().getMenuList();
            return response.data;
          } else {
            return Promise.reject(response);
          }

        }).catch(err => {
          return Promise.reject(err);

        })
    },
    async logout() {
      return new Promise<boolean>((resolve) => {
        http.removeAuthorization();
        // resetRouter()
        localStorage.clear()
        this.logged = false;
        resolve(true);
      });
    },
    async profile() {
      return http.request<Account, Response<Profile>>('/users/me', 'get').then((response: any) => {
        if (response.code == 200) {
          const { type, name, username, avatar, id } = Object.assign({}, response.data)
          const obj = { type, name, username, avatar, id }
          localStorage.setItem("user", JSON.stringify(obj))
          // localStorage.setItem("user", JSON.stringify(response.data))
          return response;
        } else {
          return Promise.reject(response);
        }
        //   if (response.code === 0) {
        //     const { setAuthorities } = useAuthStore();
        //     const { account, permissions, role } = response.data;
        //     this.account = account;
        //     this.permissions = permissions;
        //     this.role = role;
        //     setAuthorities(permissions);
        //     return response.data;
        //   } else {
        //     return Promise.reject(response);
        //   }
      });
    },
    setLogged(logged: boolean) {
      this.logged = logged;
    },
  },
});
