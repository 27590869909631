<template>
  <a-config-provider :locale="zhCN">
    <ThemeProvider is-root v-bind="themeConfig" :apply-style="false">
      <stepin-view :class="`${contentClass}`" :user="user" :navMode="navigation" :useTabs="useTabs"
        :themeList="themeList" v-model:show-setting="showSetting" v-model:theme="theme" @themeSelect="configTheme">
        <template #headerActions>
          <HeaderActions @showSetting="showSetting = true" />
        </template>
        <template #themeEditorTab>
          <a-tab-pane tab="其它" key="other">
            <Setting />
          </a-tab-pane>
        </template>
      </stepin-view>
    </ThemeProvider>
  </a-config-provider>
  <login-modal :unless="['/login']" />
</template>

<script lang="ts" setup>
import { reactive, ref, watch, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useAccountStore, useMenuStore, useSettingStore, storeToRefs } from '@/store';
import { PageFooter, HeaderActions } from '@/components/layout';
import Setting from './components/setting';
import { LoginModal } from '@/pages/login';
import { configTheme, themeList } from '@/theme';
import { ThemeProvider } from 'stepin';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
const { logout, profile } = useAccountStore();
const router = useRouter();
// 获取个人信息
watch(() => router.currentRoute.value.path, (topath) => {
  if (topath != "/login") {
    if (user.avatar === undefined && !localStorage.getItem("user")) {
      profile().then((response) => {
        if (response.code == 200) {
          user.name = response.data.username;
          user.avatar = response.data.avatar != '' ? response.data.avatar : undefined;
        }
      });
    } else {
      const me = JSON.parse(localStorage.getItem("user"))
      user.name = me.username;
      user.avatar = me.avatar != "" ? me.avatar : undefined;
    }
  }

})
const imageUpdate = (img) => {
  if (!img) return undefined;
  const imgs = new Image()
  const canvas: any = document.createElement("canvas")
  canvas.width = 500;
  canvas.height = 500;
  const cut = canvas.getContext('2d')
  let imgBase = ""
  imgs.onload = function () {
    console.log(this);
    const { width, height }: any = this
    cut.drawImage("https://copyright.bdstatic.com/vcg/creative/cc9c744cf9f7c864889c563cbdeddce6.jpg@h_1280", 0, 0, width, width, 0, 0, width, width)
    console.log(canvas.toDataURL());
    imgBase = canvas.toDataURL()
    console.log(imgBase);
    user.avatar = imgBase
  }
  imgs.src = img
  return imgBase;
}
const showSetting = ref(false);
useMenuStore().getMenuList();
const { navigation, useTabs, theme, contentClass } = storeToRefs(useSettingStore());
const themeConfig = computed(() => themeList.find((item) => item.key === theme.value)?.config ?? {});
const user = reactive({
  name: "",
  avatar: undefined,
  menuList: [
    { title: '设置', key: 'setting', icon: 'SettingOutlined', onClick: () => (showSetting.value = true) },
    { type: 'divider' },
    {
      title: '退出登录',
      key: 'logout',
      icon: 'LogoutOutlined',
      onClick: () => {
        user.name = "";
        user.avatar = undefined;
        logout().then(() => router.push('/login'))
      },
    },
  ],
});
</script>

<style lang="less">
.stepin-view {
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border-radius: 4px;
    background-color: theme('colors.primary.500');
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: theme('colors.primary.400');

    &:hover {
      background-color: theme('colors.primary.500');
    }
  }

  .stepin-logo {
    display: none;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
    border-radius: 4px;
    background: theme('backgroundColor.layout');
  }
}

html {
  height: 100vh;
  overflow-y: hidden;
}

body {
  margin: 0;
  height: 100vh;
  overflow-y: hidden;
}

p {
  margin: 0;
}

.stepin-img-checkbox {
  @apply transition-transform;

  &:hover {
    @apply scale-105~"-translate-y-[2px]";
  }

  img {
    @apply shadow-low rounded-md transition-transform;
  }
}

:deep .ant-select-selection-overflow {
  display: none;
}
</style>
